import React from 'react';
import { Modal } from '../../components/modal';
import style from './style.module.scss';

export const BonusModal = ({
	isVisible,
	setIsVisible,
	bonusMessage,
	onAcceptBonus,
	onDeclineBonus,
	getCurrencySymbol,
	bonusType,
	bonusStatus,
}) => {
	return (
		<Modal
			open={isVisible}
			setOpen={setIsVisible}
			onCloseModal={onDeclineBonus}
			closeOnBackdropClick={false}
			showCloseButton={false}
			className={style.bonusModalWrapper}
		>
			<div className="modalContent">
				<div className="modalHeader">
					<p className={style.modalBigText}>You have received</p>
				</div>
				
				{bonusType === 'Deposit' ? (
					<div>
						<div className={style.depositAmountPosition}>
							<span className={style.amountStyle}>
								{bonusMessage?.bonusConfiguration.percentageOfDeposit}
							</span>
							<span className={style.amountStyle}>%</span>
							<span className={style.modalDepositText}>Bonus</span>
						</div>
						<div className={style.depositSecondSection}>
							<span className={style.depositText}>
								{' '}
								if you deposit at least
							</span>
							<span className={style.amountStyle}>
								{bonusMessage?.bonusConfiguration?.minimumDepositAmount}{' '}
								{getCurrencySymbol(bonusMessage?.currency)}
							</span>
						</div>
					</div>
				) : null}
				{bonusType === 'Non-deposit' || bonusStatus === 'Pending' ? (
					<div className={style.amountPosition}>
						<span className={style.amountStyle}>{bonusMessage?.amount}</span>
						<span className={style.amountStyle}>
							{getCurrencySymbol(bonusMessage?.currency)}
						</span>
					</div>
				) : null}
                {(bonusType !== 'Deposit' && bonusType !== 'Free-spins') && (
					<div className="modalMessage">
						<p className={style.modalText}>
							{bonusMessage?.eventName === 'depositBonusTrigger'
								? 'Deposit Bonus'
								: 'Bonus'}
						</p>
					</div>
				)}
				{bonusType === 'Free-spins' || bonusStatus === 'Pending' ? (
					<div className={style.freeSpinsPosition}>
						<div className={style.freeSpinsTop}>
							<span className={style.amountStyle}>
								{bonusMessage?.bonusConfiguration?.numberOfFreeRounds}
							</span>
							<span className={style.freeSpinsText}>Free Spins</span>
						</div>
						<div className={style.freeSpinsBottom}>
							<span className={style.freeSpinsText}>for</span>
							<span className={style.amountStyle}>
								{bonusMessage?.bonusConfiguration?.coinValue}
							</span>
							<span className={style.amountStyle}>
								{getCurrencySymbol(bonusMessage?.currency)}
							</span>
						</div>
					</div>
				) : null}
				
				<p
					className={style.footerText}
					onClick={() => console.log('Placeholder')}
				>
					Terms and conditions
				</p>
				<div className={style.buttons}>
					<button className={style.declineButton} onClick={onDeclineBonus}>
						Decline
					</button>
					<button className={style.acceptButton} onClick={onAcceptBonus}>
						Accept
					</button>
				</div>
			</div>
		</Modal>
	);
};
