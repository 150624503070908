import { clsx } from 'clsx';
import { route } from 'preact-router';
import { useMemo, useState } from 'preact/hooks';
import style from './style.module.scss';
import { Typography } from '../typography';
import PlayButton from '../../assets/icons/Vector.svg';
import InfoIcon from '../../assets/icons/info-icon.svg';
import ForYouRibbon from '../../assets/icons/for-you-ribbon.svg';
import HighVolatilityRibbon from '../../assets/icons/high-volatility-ribbon.svg';
import { useViewport } from '../../hooks/useViewport';
import { t } from 'i18next';
import { useCustomGameImages } from '../../hooks/useCustomGameImages';
import GameCardInfo from "../game-card-new-info";
import {useDispatch} from "react-redux";
import {addRecentlyPlayedGames} from "../../store/user-slice";

const GameCard = ({
	game: apiGame,
	category = '',
	onClickGameCallback = () => {},
	isLastVisibleItem,
}) => {
	const dispatch = useDispatch();
	const { isMobileScreen } = useViewport();
	const [isHovered, setIsHovered] = useState(false);
	const [addedHoverAnimation, setAddedHoverAnimation] = useState(true);
	const [showInfo, setShowInfo] = useState(false);
	const { game } = useCustomGameImages({ apiGame })

	const handleOnInfoClick = () => {
		setAddedHoverAnimation(false);
		setShowInfo(true);
	};

	const renderHoveredState = () => {
		return (
			<div className={style.hoveredState}>
				<Typography type="h5" className={style.gameName} title={game.name}>
					{game.name}
				</Typography>
				<img src={PlayButton} alt="play-button" onClick={onClickGame} />
				<Typography type="st1" className={style.providerName}>
					{game.subVendor ? game.subVendor : game.provider}
				</Typography>
			</div>
		);
	};

	const onClickGame = () => {
		dispatch(addRecentlyPlayedGames({ gameId: game.gameId?.toString() || game.externalGameId }));
		route(`/casino/games/${game.gameId?.toString() || game.externalGameId}`);
		onClickGameCallback();
	};

	const ribbon = useMemo(() => {
		if (!category) {
			return null;
		}

		if (category === t('special_for_you') || category === 'special_for_you') {
			return (
				<div className={style.ribbon} onClick={isMobileScreen ? onClickGame : () => {}}>
					<Typography className={style.forYouText}>For you</Typography>
					<img src={ForYouRibbon} alt="for-you-ribbon" />
				</div>
			);
		} else if (category === t('new_games') || category === 'new_games') {
			return (
				<div className={style.ribbon} onClick={isMobileScreen ? onClickGame : () => {}}>
					<Typography className={style.newText}>New</Typography>
					<img src={HighVolatilityRibbon} alt="high-volatility-ribbon" />
				</div>
			);
		} else if (
			category === t('jackpot_games') ||
			category === 'jackpot_games'
		) {
			return (
				<div className={style.ribbon} onClick={isMobileScreen ? onClickGame : () => {}}>
					<Typography className={clsx(
						game.volatility.length <= 4 && style.shortVolatilityText,
						(game.volatility.length > 4 && game.volatility.length <= 6) && style.mediumVolatilityText,
						game.volatility.length > 6 && style.longVolatilityText,
						)}>
						{game.volatility}
					</Typography>
					<img src={HighVolatilityRibbon} alt="high-volatility-ribbon" />
				</div>
			);
		}
	}, [category, onClickGame, isMobileScreen]);

	if (!game) {
		return;
	}

	return (
		<div
			className={clsx(
				style.cardWrapper,
				showInfo && style.turnCard,
				// isLastVisibleItem && style.cardWrapperOpacity,
				!showInfo && isHovered && addedHoverAnimation && !isMobileScreen && style.cardWrapperHover
			)}
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
		>
			{isHovered && !showInfo && !isMobileScreen ? renderHoveredState() : null}
			<div className={style.card}>
				<img
					src={game.thumbnail}
					className={style.image}
					loading="lazy"
					alt={`${game.name}-image`}
					onClick={isMobileScreen ? onClickGame : () => {}}
				/>
				{!game.lobby?.includes('LiveCasino') && <img
					className={style.infoIcon}
					src={InfoIcon}
					onClick={handleOnInfoClick}
					alt="infoIcon"
				/>}
				{ribbon}
			</div>
			{!game.lobby?.includes('LiveCasino') && <GameCardInfo game={game}
										 onClickGame={onClickGame}
										 setShowInfo={setShowInfo}
										 setAddedHoverAnimation={setAddedHoverAnimation}/>}
		</div>
	);
};

export default GameCard;
