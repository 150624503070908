import {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'preact/hooks';
import { Router as PreactRouter } from 'preact-router';
import { useSelector, useDispatch } from 'react-redux';
import i18n from 'i18next';
import CasinoPage from './pages/casino-page/index.js';
import CasinoGamePage from './pages/casino-game-page/index.js';
import ButtonsDesignSystemPage from './pages/buttons-design-page/index.js';
import LiveCasinoPage from './pages/live-casino-page/index.js';
import TransactionsPage from './pages/transactions-page/index.js';
import SettingsPage from './pages/settings-page/index.js';
import ResetPasswordPage from './pages/reset-password-page';
import PromotionsPage from './pages/promotions-page';
import EmailVerificationPage from './pages/email-verification-page';
import InfoPage from './pages/info-page';
import PromotionsDetailsPage from './pages/promotions-details-page';
import ResponsibleGamingSettings from './pages/responsible-gaming-settings';
import ResponsibleGamingPage from './pages/responsible-gaming-page';
import ProtectedRoute from './components/protected-route';
import MainLoader from './components/main-loader';
import MyAccountPage from './pages/my-account-page';

import useUserSession from './hooks/useUserSession.js';

import worker from './new-ww/script.js';
import { useWebWorker } from './new-ww/useWebWorker.js';
import { createWebWorker } from './new-ww/web-worker.js';

import MainWrapper from './components/main-wrapper/index.js';
import SignUpModal from './components/sign-up-modal/index.js';
import SignInModal from './components/sign-in-modal/index.js';
import ForgotPassword from './components/forgot-password/index.js';

import {
	setShowLogin,
	setShowSignUp,
	selectShowLogin,
	selectShowSignUp,
	selectShowForgotPassword,
	setShowForgotPassword,
} from './store/theme-slice.js';
import {
	logoutUser,
	selectIsInitiallyVerifyCompleted,
} from './store/authentication-slice';
import { selectPlayerData } from './store/player-slice.js';
import { mapMessageToWebWorker } from './utils/map-message.js';
import { UrlContext } from './providers/url-provider/index.js';
import {
	fetchBanners,
	fetchFooterContent,
	fetchPages,
	fetchPromotions,
	fetchProviders,
	fetchCasinoMenu,
	selectIsFetchCasinoMenuCompleted,
} from './store/content-slice';
import PasswordReset from './components/password-reset/index.js';
import { selectUser } from './store/user-slice.js';

import { UserActiveStatus } from './constants/enums';
import * as translationsService from './translations';
import constants from './constants/general.js';
import RealityCheckListener from './components/RealityCheckListener/index.js';
import TournamentsPage from './pages/tournaments-page/index.js';
import TournamentDetailsPage from './pages/tournaments-details-page/index.js';
import CheckAndSetTimer from './components/CheckAndSetTimer/index.js';
import BonusEventListener from './components/BonusEventListener/index.jsx';

export const Router = () => {
	const dispatch = useDispatch();
	const workerInstance = useMemo(() => createWebWorker(worker), []);
	useUserSession();

	const user = useSelector(selectUser);

	const hideWidget = () => {
		try {
			window.zE('messenger', 'hide');
			window.zE('messenger', 'close');
		} catch (error) {}
	};
	const showWidget = () => {
		try {
			window.zE('messenger', 'show');
		} catch (error) {}
	};
	useEffect(() => {
		user?.id ? showWidget() : hideWidget();
	}, [user?.id]);

	const { onUpdateUrl } = useContext(UrlContext);

	const isInitiallyVerifyCompleted = useSelector(
		selectIsInitiallyVerifyCompleted
	);
	const isFetchCasinoMenuCompleted = useSelector(
		selectIsFetchCasinoMenuCompleted
	);
	const player = useSelector(selectPlayerData);

	useEffect(() => {
		window.fetchWorker = workerInstance;
	}, []);
	const { startProcessing } = useWebWorker(workerInstance);

	useEffect(() => {
		const getVendorsMessage = mapMessageToWebWorker({
			method: 'GET',
			action: 'getVendors',
			collection: 'vendors',
		});

		startProcessing(getVendorsMessage);
	}, [startProcessing, dispatch]);

	const showLogin = useSelector(selectShowLogin);
	const showSignUp = useSelector(selectShowSignUp);
	const showForgotPassword = useSelector(selectShowForgotPassword);

	const hideLogin = () => {
		dispatch(setShowLogin(false));
	};

	const hideSignUp = () => {
		dispatch(setShowSignUp(false));
	};

	const redirectToSignUp = () => {
		hideLogin();
		dispatch(setShowSignUp(true));
	};

	const redirectToSignIn = () => {
		hideSignUp();
		dispatch(setShowLogin(true));
	};

	const redirectToForgotPassword = () => {
		hideLogin();
		dispatch(setShowForgotPassword(true));
	};

	const hideForgotPassword = () => {
		dispatch(setShowForgotPassword(false));
	};

	useEffect(() => {
		dispatch(fetchBanners());
		dispatch(fetchPages());
		dispatch(fetchPromotions());
		dispatch(fetchProviders());
		dispatch(fetchFooterContent());
		dispatch(fetchCasinoMenu());
	}, [dispatch]);

	//Listens if the player status is SUSPENDED and logs him out with reason system-terminations
	useEffect(() => {
		if (player && player.userActiveStatus === UserActiveStatus.SUSPENDED) {
			//     dispatch(
			//       logoutUser({
			//         reason: "system-terminations",
			//         logoutTime: new Date().getTime(),
			//       }),
			//     );
			dispatch(logoutUser());

			//     if (router.pathname === config.routes.responsibleGaming) {
			//       router.push(config.routes.casino, undefined, { shallow: true });
			//     }
		}
	}, [player, player?.userActiveStatus, dispatch]);

	const handleUrlChange = useCallback(
		({ url }) => {
			onUpdateUrl(url);
			window.scrollTo(0, 0);
		},
		[onUpdateUrl]
	);

	useEffect(() => {
		if (!isInitiallyVerifyCompleted) {
			return;
		}

		let transalationsLanguage = user?.language?.toLowerCase();

		if (!transalationsLanguage) {
			transalationsLanguage = sessionStorage.getItem(
				constants.SESSION_STORAGE_SITE_LANGUAGE
			);
		}

		if (!i18n.isInitialized) {
			translationsService.initDict(transalationsLanguage);
		} else {
			i18n.changeLanguage(transalationsLanguage);
		}
	}, [user, isInitiallyVerifyCompleted]);

	return !isInitiallyVerifyCompleted ||
		!isFetchCasinoMenuCompleted ||
		!i18n.isInitialized ? (
		<MainLoader />
	) : (
		<MainWrapper>
			<CheckAndSetTimer />
			<RealityCheckListener />
			<BonusEventListener/>
			{showLogin && (
				<SignInModal
					open={showLogin}
					onCloseModal={hideLogin}
					handleRedirectToSignUp={redirectToSignUp}
					handleRedirectToForgotPassword={redirectToForgotPassword}
				/>
			)}

			{showSignUp && (
				<SignUpModal
					open={showSignUp}
					onCloseModal={hideSignUp}
					handleRedirectToLogin={redirectToSignIn}
				/>
			)}

			<ForgotPassword
				open={showForgotPassword}
				onCloseModal={hideForgotPassword}
			/>

			<div id="app">
				<main>
					<PreactRouter onChange={handleUrlChange}>
						<CasinoPage path="/" />
						<CasinoGamePage path="/casino/games/:id" />

						<CasinoPage path="/casino/:rest*" />

						<LiveCasinoPage path="/live-casino/:rest*" />

						<TournamentsPage path="/tournaments" />

						<TournamentDetailsPage path="/tournaments/:id" />

						<PromotionsPage path="/promotions" />

						<PromotionsDetailsPage path="/promotions/:slug" />

						<InfoPage path="/info/:slug" />

						<ResponsibleGamingPage path="/responsible-gaming" />

						<ResetPasswordPage path="/forgot-password-reset-flow" />

						<ButtonsDesignSystemPage path="/buttons" />

						<EmailVerificationPage path="/email-verification" />

						<PasswordReset path="/password-reset-done" />

						<ProtectedRoute
							component={TransactionsPage}
							path="/transactions/:rest*"
						/>

						<ProtectedRoute component={SettingsPage} path="/settings/:rest*" />

						<ProtectedRoute
							component={ResponsibleGamingSettings}
							path="settings/responsible-gaming/:rest*"
						/>

						<ProtectedRoute component={MyAccountPage} path="/account/:rest*" />
					</PreactRouter>
				</main>
			</div>
		</MainWrapper>
	);
};

export default Router;
