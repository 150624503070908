export const CurrencySigns = {
	EUR: '€',
	USD: '$',
	GBP: '£',
	BTC: 'BTC',
	ETH: 'ETH',
	JPY: '¥',
	AUD: 'A$',
	CAD: 'C$',
	CHF: 'CHF',
	CNY: '¥',
	HKD: 'HK$',
	NZD: 'NZ$',
	SEK: 'SEK',
	KRW: '₩',
	SGD: 'S$',
	NOK: 'kr',
	MXN: '$',
	INR: '₹',
	RUB: '₽',
	ZAR: 'R',
	TRY: '₺',
	BRL: 'R$',
	TWD: 'NT$',
	PLN: 'zł',
	THB: '฿',
	MYR: 'RM',
	IDR: 'Rp',
	PHP: '₱',
	VND: '₫',
	NGN: '₦',
	EGP: 'E£',
	SAR: 'ر.س',
	ILS: '₪',
	AED: 'د.إ',
	ARS: '$',
	CLP: '$',
	COP: '$',
	CZK: 'Kč',
	DKK: 'kr',
	HUF: 'Ft',
	ISK: 'kr',
	KZT: '₸',
	MAD: 'د.م.',
	PEN: 'S/',
	PKR: '₨',
	QAR: 'ر.ق',
	RON: 'lei',
	UAH: '₴',
	BDT: '৳',
	LKR: '₨',
	JMD: '$',
	KES: 'KSh',
	GHS: '₵',
	XOF: 'CFA',
	XAF: 'FCFA',
	BGN: 'лв',
	HRK: 'kn',
	BHD: 'ب.د',
	OMR: 'ر.ع.',
	JOD: 'د.ا',
	KWD: 'د.ك',
	MUR: '₨',
	NAD: 'N$',
	SCR: '₨',
	SYP: 'ل.س',
	BND: '$',
	MMK: 'Ks',
};

export const DATE_FILTERS = [
	'all_time',
	'last_24_hours',
	'last_week',
	'last_month',
	'last_3_months',
	'custom',
];

export const DATE_FILTERS_EPOCH_TIME_MS = {
	last_24_hours: Date.now() - 24 * 60 * 60 * 1000,
	last_week: Date.now() - 7 * 24 * 60 * 60 * 1000,
	last_month: Date.now() - 30 * 24 * 60 * 60 * 1000,
	last_3_months: Date.now() - 90 * 24 * 60 * 60 * 1000,
};

export const TRANSACTION_FILTERS = [
	'all_transactions',
	'deposit_history',
	'withdrawal_history',
];

export const bonusEvents = [
	'loginBonusTrigger',
	'depositBonusTrigger',
	'freeSpinsBonusTrigger',
	'cashbackBonusTrigger',
	'signupBonusTrigger',
	'betBonusTrigger',
	'winBonusTrigger',
	'lossBonusTrigger',
  ];