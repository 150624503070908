import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { bonusEvents, CurrencySigns } from '../../utils/consts';
import {
	acceptOrDeclineBonus,
	triggerBonusConditionsCheck,
} from '../../services/bonus-service';
import { BonusModal } from '../bonus-modal';

export const BonusEventPopUp = ({ socket, player }) => {
	const [bonusMessage, setBonusMessage] = useState(null);
	const [isVisible, setIsVisible] = useState(false);
	const [bonusQueue, setBonusQueue] = useState([]);
	useEffect(() => {
		if (player?.bonuses && player.bonuses.length > 0) {
			const inactiveWithActiveCampaign = player.bonuses.find(
				(bonus) =>
					bonus.status === 'Inactive' &&
					bonus.bonusConfiguration?.campaignStatus === 'Active'
			);
			if (inactiveWithActiveCampaign) {
				addBonusToQueue(inactiveWithActiveCampaign);
			}
		}
	}, [player]);

	useEffect(() => {
		if (socket) {
			socket.on('assignBonus', (msg) => {
				if (msg.status === 'Inactive') {
					addBonusToQueue(msg);
				}
			});
            socket.on('show-deposit-bonus-modal',(msg)=>{
                if(msg.status==='Inactive'){
                    addBonusToQueue(msg)
                }
            })
			bonusEvents.forEach((eventName) => {
				socket.on(eventName, (msg) => {
					if (msg.bonusConfiguration.campaignStatus === 'Active') {
						addBonusToQueue({ eventName, ...msg });
					}
				});
			});
		}

		return () => {
			if (socket) {
				bonusEvents.forEach((eventName) => {
					socket.off(eventName);
				});
				socket.off('assignBonus');
                socket.off('show-deposit-bonus-modal');
			}
		};
	}, [socket]);

	useEffect(() => {
		if (bonusQueue.length > 0) {
			if (!isVisible) {
				processNextBonus();
			}
		}
	}, [bonusQueue, isVisible]);

	const processNextBonus = () => {
		setBonusQueue((prevQueue) => {
			if (prevQueue.length > 0) {
				const [nextBonus, ...remainingQueue] = prevQueue;
				setBonusMessage(nextBonus);
				setIsVisible(true);
				return remainingQueue;
			} else {
				setIsVisible(false);
				setBonusMessage(null);
				return [];
			}
		});
	};

	const handleAcceptBonus = async () => {
		try {
			await acceptOrDeclineBonus({
				id: Number(bonusMessage.id),
				status: 'Accepted',
			});
			await triggerBonusConditionsCheck({
				playerId: bonusMessage.playerId,
				currency: bonusMessage.currency,
			});
		} catch (error) {
			console.log('Error accepting bonus: ', error);
		} finally {
			setIsVisible(false);
			processNextBonus();
		}
	};

	const handleDeclineBonus = async () => {
		try {
			await acceptOrDeclineBonus({
				id: Number(bonusMessage.id),
				status: 'Declined',
			});
		} catch (error) {
			console.log('Error declining bonus: ', error);
		} finally {
			setIsVisible(false);
			processNextBonus();
		}
	};

	const handleAcceptDepositBonus = async (data) => {
        try {
            if (!socket) return;
            socket.emit("deposit-bonus", data);
        } catch (error) {
            console.log('Error accepting deposit bonus: ', error);
        }finally {
			setIsVisible(false);
			processNextBonus();
		}
    };
	const handleDeclineDepositBonus = async (data) => {
        try {
            if (!socket) return;
            socket.emit("deposit-bonus", data);
        } catch (error) {
            console.log('Error declining deposit bonus: ', error);
        }finally {
			setIsVisible(false);
			processNextBonus();
		}
    };

	const getCurrencySymbol = (currency) => {
		return CurrencySigns[currency] || '';
	};

	const addBonusToQueue = (newBonus) => {
		setBonusQueue((prevQueue) => {
			const isBonusInQueue = prevQueue.some(
				(bonus) => bonus.id === newBonus.id
			);
			if (!isBonusInQueue) {
				return [...prevQueue, newBonus];
			}
			return prevQueue;
		});
	};
	return (
		<div>
			{(isVisible &&  bonusMessage.bonusConfiguration.type !== "Deposit") &&(
				<BonusModal
					isVisible={isVisible}
					setIsVisible={setIsVisible}
					bonusMessage={bonusMessage}
					onAcceptBonus={handleAcceptBonus}
					onDeclineBonus={handleDeclineBonus}
					getCurrencySymbol={getCurrencySymbol}
					bonusType={bonusMessage.bonusType}
					bonusStatus={bonusMessage.status}
				/>
			)}
			{(isVisible && bonusMessage.bonusConfiguration.type==="Deposit") && (
				<BonusModal
					isVisible={isVisible}
					setIsVisible={setIsVisible}
					bonusMessage={bonusMessage}
					onAcceptBonus={()=>handleAcceptDepositBonus(bonusMessage)}
					onDeclineBonus={()=>handleDeclineDepositBonus(bonusMessage)}
					getCurrencySymbol={getCurrencySymbol}
					bonusType={bonusMessage.bonusConfiguration.type}
					bonusStatus={bonusMessage.status}
				/>
			)}
		</div>
	);
};
