import { useSelector } from "react-redux";
import { BonusEventPopUp } from "../../pages/bonus-event-popups";
import { useSocketContext } from "../../providers/socket-provider";
import { selectPlayerData } from "../../store/player-slice";


const BonusEventListener = () => {
  const socket = useSocketContext();
  const player = useSelector(selectPlayerData);
  
  return <BonusEventPopUp socket={socket} player={player} />;
};

export default BonusEventListener;