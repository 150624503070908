import handleFetch from '../utils/handleFetch';
import { getAuthHeaders } from '../utils/auth';
import { sapphirePlayerApiUrl,baseSapphireApiUrl } from './constants';

const claimBonus = async (playerId, code) => {
	return handleFetch(`${sapphirePlayerApiUrl}/bonus/${playerId}`, {
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
		body: JSON.stringify({ code }),
		method: 'POST',
	});
};

// type PlayerBonusActionStatusBody = {
// 	status: string;
// 	id: number | undefined;
//   };
// const acceptOrDeclineBonus = async (data: PlayerBonusActionStatusBody) => {
const acceptOrDeclineBonus = async (data) => {
	const { id, status } = data;
	const url = `${sapphirePlayerApiUrl}/bonus/${id}`;
	const options = {
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
		body: JSON.stringify({ status }),
	};
	const result = await handleFetch(url, options);
	return result;
};
// type BonusConditionCheckType{
// 	playerId: number;
// 	currency: string;
// }
// const triggerBonusConditionsCheck = async (data:BonusConditionCheckType) => {

const triggerBonusConditionsCheck = async (data) => {
	const {playerId,currency}=data;
	const url=`${baseSapphireApiUrl}/casino/bonuses/check-conditions/${playerId}?currency=${currency}`;
	const options={
		method: 'GET',
		headers:{
			"Content-Type": "application/json",
			...getAuthHeaders(),
		}
	}
	return await fetch(url,options);
	// const result=await handleFetch(url,options);
	// return result;
};
export { claimBonus, acceptOrDeclineBonus, triggerBonusConditionsCheck };
